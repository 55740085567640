import React, { useEffect, useState } from "react";
import Spline from "@splinetool/react-spline";
import { Link } from "react-router-dom";

import { Row, Col, Container } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import NavDropdown from "react-bootstrap/NavDropdown";
import Gifone from "../Assets/images/Section-1.gif";
import Giftwo from "../Assets/images/Section-2.gif";
import Gifthree from "../Assets/images/Section-3.gif";
import ScrollAnimation from "react-animate-on-scroll";
import { CookiesProvider } from "react-cookie";

export default function Home() {
  const [scrollY, setScrollY] = useState(0);

  useEffect(() => {
    const handleScroll = () => {
      setScrollY(window.scrollY);
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <>
      <header>
        <Container>
          <div className="logo">
            <img
              src={require("../Assets/images/Cluberly-Logo-Revisions_Nov23-01.webp")}
            />
          </div>
        </Container>
      </header>

      <div className="combine-sections">
        <section className="banner">
          <Container>
            <Row>
              <Col lg={6}>
                <div className="mob-phones">
                  <ScrollAnimation animateIn="zoomInDown" animateOnce={true}>
                    <img
                      className="img-fluid"
                      src={require("../Assets/images/phones.webp")}
                    />
                  </ScrollAnimation>
                </div>
              </Col>
              <Col lg={6}>
                <div className="right-sec">
                  <img
                    className="img-fluid animate__animated animate__flipInY"
                    src={require("../Assets/images/clogo.webp")}
                  />
                  <h2 className="main-heading animate__animated animate__flipInY">
                    <span>INTRODUCING</span> CLUBERLY THE <span>FIRST</span>{" "}
                    SAVINGS APP DEDICATED TO <span>SPORTS</span> FANS{" "}
                  </h2>
                </div>
              </Col>
            </Row>
          </Container>
        </section>

        <div className="circled-main banner-circle">
          <div className={`animated-box ${scrollY > 400 ? "set" : ""}`}>
            <div className="circled-line">
              {/* <ScrollAnimation
                className="delay-one"
                animateIn="fadeInDown"
                animateOnce={true}
                durationAppear={10000}
              > */}
              <span className="first-circle"></span>
              {/* </ScrollAnimation> */}
              {/* <ScrollAnimation
              className="delay-two"
              animateIn="fadeInDown"
              animateOnce={true}
              durationAppear={10000}
            > */}

              <span className="long-line"></span>

              {/* </ScrollAnimation> */}
              {/* <ScrollAnimation
                className="delay-three"
                animateIn="fadeInDown"
                animateOnce={true}
                durationAppear={10000}
              > */}
              <span className="last-circle"></span>
              {/* </ScrollAnimation> */}
            </div>
          </div>
        </div>

        <section className="second-sec">
          <Container>
            <Row className="mob-reverse">
              <Col lg={5} className="d-flex justify-content-center">
                <div className="mob-phones">
                  <ScrollAnimation animateIn="zoomInDown" animateOnce={true}>
                    <img
                      src={require("../Assets/images/Daily Morning Motivation Quote Mockup Instagram Post copy.webp")}
                    />
                  </ScrollAnimation>
                </div>
              </Col>

              <Col lg={7} className="d-flex align-items-center">
                <div className="right-sec first-heading">
                  <ScrollAnimation animateIn="flipInY" animateOnce={true}>
                    <h2 className="main-heading grey width-set ">
                      SET SAVINGS
                      <br />
                      <span>GOALS</span> - INVEST
                      <br /> FOR THE <span>FUTURE</span> <br />& HELP SUPPORT
                      <br />
                      <span>CAUSES</span> YOU ARE
                      <br /> PASSIONATE <br />
                      ABOUT
                    </h2>
                  </ScrollAnimation>
                  <ScrollAnimation animateIn="zoomIn" animateOnce={true}>
                    {" "}
                    <p className="sub-font">
                      Welcome to Cluberly, your straightforward solution to
                      savings and investment. New to saving? Our 'Round-Up'
                      feature effortlessly accumulates your spare change from
                      everyday purchases. New to investing? No problem. Cluberly
                      offers access to expertly-curated portfolios from
                      renowned, regulated managers. Start your investment
                      journey with as little as £1 and choose a portfolio that
                      aligns with your preferences.
                    </p>
                  </ScrollAnimation>
                </div>
              </Col>
            </Row>
          </Container>
        </section>
      </div>

      <div className="circled-main second-sec">
        <div className={`animated-box ${scrollY > 1500 ? "set-two" : ""}`}>
          <div className="circled-line">
            {/* <ScrollAnimation
            className="delay-one"
            animateIn="fadeInDown"
            animateOnce={true}
          > */}
            <span className="first-circle"></span>
            {/* </ScrollAnimation> */}
            {/* <ScrollAnimation
            className="delay-two"
            animateIn="fadeInDown"
            animateOnce={true}
          > */}

            <span className="long-line"></span>

            {/* </ScrollAnimation> */}
            {/* <ScrollAnimation
            className="delay-three"
            animateIn="fadeInDown"
            animateOnce={true}
          > */}
            <span className="last-circle"></span>
            {/* </ScrollAnimation> */}
          </div>
        </div>
      </div>

      <section className="second-sec px-0 pt-0">
        <Container>
          <Row>
            <Col
              lg={7}
              className="d-flex align-items-center justify-content-center"
            >
              <div className="right-sec second-heading">
                <ScrollAnimation animateIn="flipInY" animateOnce={true}>
                  <h2 className="main-heading grey width-set">
                    <span> NOMINATE</span> A<br /> CLUB CHARITY OR <br />
                    SCHOOL AND WE WILL
                    <span> DONATE</span>
                    <br />
                    50% OF OUR FEES
                    <br /> TO YOUR CHOSEN
                    <br />
                    <span>CAUSE</span>
                  </h2>
                </ScrollAnimation>
                <ScrollAnimation animateIn="zoomIn" animateOnce={true}>
                  {" "}
                  <p className="sub-font">
                    Cluberly stands out in the financial app market. While we
                    operate with a modest subscription fee, we are the first to
                    pledge 50% of these fees to causes chosen by our users. Your
                    choices drive our giving, making every investment with
                    Cluberly a step towards supporting your community.
                  </p>
                </ScrollAnimation>
              </div>
            </Col>
            <Col lg={5} className="d-flex justify-content-center">
              <div className="mob-phones">
                <ScrollAnimation animateIn="flipInY" animateOnce={true}>
                  <img src={require("../Assets/images/mockup.webp")} />
                </ScrollAnimation>
              </div>
            </Col>
          </Row>
        </Container>
      </section>

      <div className="circled-main black-sec-line">
        <div className={`animated-box ${scrollY > 2500 ? "set-two" : ""}`}>
          <div className="circled-line">
            {/* <ScrollAnimation
            className="delay-one"
            animateIn="fadeInDown"
            animateOnce={true}
            durationAppear={10000}
          > */}
            <span className="first-circle"></span>
            {/* </ScrollAnimation> */}
            {/* <ScrollAnimation
            className="delay-two"
            animateIn="fadeInDown"
            animateOnce={true}
            durationAppear={10000}
          > */}
            <span className="long-line"></span>
            {/* </ScrollAnimation> */}
            {/* <ScrollAnimation
            className="delay-three"
            animateIn="fadeInDown"
            animateOnce={true}
            durationAppear={10000}
          > */}
            <span className="last-circle"></span>
            {/* </ScrollAnimation> */}
          </div>
        </div>
      </div>

      <section className="black-sec first">
        <Container>
          <Row className="mob-reverse">
            <Col lg={5} className="d-flex justify-content-center">
              <div className="mob-phones">
                <ScrollAnimation
                  animateIn="wobble"
                  animateOnce={true}
                  animationDelay={1000}
                  animateDuration={3000}
                >
                  <img src={require("../Assets/images/blacksec.webp")} />
                </ScrollAnimation>
              </div>
            </Col>
            <Col lg={7} className="d-flex align-items-center">
              <div className="right-sec third-heading">
                <ScrollAnimation animateIn="flipInY" animateOnce={true}>
                  <h2 className="main-heading width-set">
                    SAVING &<br />
                    INVESTING MADE
                    <br />
                    <span>EASY</span>
                    <br />
                    SET YOUR GOALS
                    <br />
                    TAKE ON THE
                    <br />
                    CHALLENGES &
                    <br />
                    <span>PROSPER</span>
                  </h2>
                </ScrollAnimation>{" "}
                <ScrollAnimation animateIn="zoomIn" animateOnce={true}>
                  <p className="sub-font">
                    Whether saving for a future goal, an upcoming event, or just
                    a rainy day, Cluberly turns saving into an enjoyable and
                    rewarding journey. Set personal goals or take on unique
                    challenges. Achieve them and reap additional rewards. Saving
                    with Cluberly isn’t just about numbers; it’s about
                    fulfilling your financial dreams with ease and fun.
                  </p>
                </ScrollAnimation>
              </div>
            </Col>
          </Row>
        </Container>
      </section>

      <div className="circled-main inner-black">
        <div className={`animated-box ${scrollY > 3200 ? "set-two" : ""}`}>
          <div className="circled-line">
            {/* <ScrollAnimation
              className="delay-one"
              animateIn="fadeInDown"
              animateOnce={true}
              durationAppear={10000}
            > */}
            <span className="first-circle"></span>
            {/* </ScrollAnimation> */}
            {/* <ScrollAnimation
              className="delay-two"
              animateIn="fadeInDown"
              animateOnce={true}
              durationAppear={10000}
            > */}
            <span className="long-line"></span>
            {/* </ScrollAnimation> */}
            {/* <ScrollAnimation
              className="delay-three"
              animateIn="fadeInDown"
              animateOnce={true}
              durationAppear={10000}
            > */}
            <span className="last-circle"></span>
            {/* </ScrollAnimation> */}
          </div>
        </div>
      </div>

      <section className="black-sec second-black">
        <Container>
          <Row>
            <Col
              lg={7}
              className="d-flex align-items-center justify-content-center"
            >
              <div className="right-sec fourth-heading">
                <ScrollAnimation
                  animateIn="flipInY"
                  animateOnce={true}
                  durationEnter={2000}
                >
                  <h2 className="main-heading width-set">
                    JOIN A<br />
                    <span>REVOLUTION</span>
                    <br />
                    THE GREATER THE
                    <br />
                    <span>NUMBERS</span>
                    <br />
                    THE GREATER
                    <br />
                    SUPPORT WE CAN
                    <br />
                    <span>GIVE</span>
                  </h2>
                </ScrollAnimation>
                <ScrollAnimation animateIn="zoomIn" animateOnce={true}>
                  {" "}
                  <p className="sub-font">
                    We're rallying a community of sports enthusiasts to help us
                    expand. The larger our community, the more we can support
                    the causes that form the bedrock of our society. Cluberly is
                    dedicated to aiding sports clubs, schools, and charities
                    across the nation, selected by you, our users.
                  </p>
                </ScrollAnimation>
              </div>
            </Col>
            <Col lg={5} className="d-flex justify-content-center">
              <div className="mob-phones">
                <ScrollAnimation animateIn="flipInY" animateOnce={true}>
                  <img src={require("../Assets/images/red.webp")} />
                </ScrollAnimation>
              </div>
            </Col>
          </Row>
        </Container>
      </section>

      <div className="circled-main next-black">
        <div className={`animated-box ${scrollY > 4500 ? "set-two" : ""}`}>
          <div className="circled-line">
            {/* <ScrollAnimation
              className="delay-one"
              animateIn="fadeInDown"
              animateOnce={true}
              durationAppear={10000}
            > */}
            <span className="first-circle"></span>
            {/* </ScrollAnimation> */}
            {/* <ScrollAnimation
              className="delay-two"
              animateIn="fadeInDown"
              animateOnce={true}
              durationAppear={10000}
            > */}
            <span className="long-line"></span>
            {/* </ScrollAnimation> */}
            {/* <ScrollAnimation
              className="delay-three"
              animateIn="fadeInDown"
              animateOnce={true}
              durationAppear={10000}
            > */}
            <span className="last-circle"></span>
            {/* </ScrollAnimation> */}
          </div>
        </div>
      </div>
      <section className="second-sec">
        <Container>
          <Row className="mob-reverse">
            <Col lg={5} className="d-flex justify-content-center">
              <div className="mob-phones">
                <ScrollAnimation animateIn="flipInY" animateOnce={true}>
                  <img src={require("../Assets/images/clothes.webp")} />
                </ScrollAnimation>
              </div>
            </Col>

            <Col lg={7} className="d-flex align-items-center">
              <div className="right-sec fifth-heading">
                <ScrollAnimation animateIn="flipInY" animateOnce={true}>
                  <h2 className="main-heading grey width-set">
                    A <span>COMMUNITY</span>
                    <br />
                    MARKET PLACE -<br />A <span>WORLD</span> WHERE
                    <br /> SHOPPING FUELS
                    <br />
                    SOCIAL <span>CHANGE</span>
                  </h2>
                </ScrollAnimation>
                <ScrollAnimation animateIn="zoomIn" animateOnce={true}>
                  {" "}
                  <p className="sub-font">
                    Discover the unique world of Cluberly's marketplace, where
                    every item tells a story of passion and purpose. Here,
                    shopping goes beyond mere transactions; it becomes a
                    powerful act of support for the causes you care about.{" "}
                    <br />
                    <br />
                    Our range of Cluberly-branded merchandise is carefully
                    curated to offer style and substance. Half the profits from
                    your purchases are dedicated to supporting community causes,
                    making each item you buy a testament to your commitment to
                    social impact.
                  </p>
                </ScrollAnimation>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
      <div className="circled-main inner-white">
        <div className={`animated-box ${scrollY > 5500 ? "set-two" : ""}`}>
          <div className="circled-line">
            {/* <ScrollAnimation
            className="delay-one"
            animateIn="fadeInDown"
            animateOnce={true}
            durationAppear={10000}
          > */}
            <span className="first-circle"></span>
            {/* </ScrollAnimation> */}
            {/* <ScrollAnimation
            className="delay-two"
            animateIn="fadeInDown"
            animateOnce={true}
            durationAppear={10000}
          > */}
            <span className="long-line"></span>
            {/* </ScrollAnimation> */}
            {/* <ScrollAnimation
            className="delay-three"
            animateIn="fadeInDown"
            animateOnce={true}
            durationAppear={10000}
          > */}
            <span className="last-circle"></span>
            {/* </ScrollAnimation> */}
          </div>
        </div>
      </div>
      <section className="second-sec">
        <Container>
          <Row>
            <Col
              lg={7}
              className="d-flex align-items-center justify-content-center"
            >
              <div className="right-sec sixth-heading">
                <ScrollAnimation animateIn="flipInY" animateOnce={true}>
                  <h2 className="main-heading grey width-set">
                    STAY <span>UPDATED</span>
                    <br />
                    WITH CLUBERLY
                    <br /> <span>NEWS</span> - YOUR <br />
                    ESSENTIAL <span>GUIDE</span> <br />
                    TO FINANCE
                    <br /> SAVINGS AND
                    <br />
                    SPORTS
                  </h2>
                </ScrollAnimation>
                <ScrollAnimation animateIn="zoomIn" animateOnce={true}>
                  {" "}
                  <p className="sub-font">
                    In a world where information is key, Cluberly News keeps you
                    at the forefront of finance, savings, and sports. Our news
                    section is more than just updates; it's a resource to help
                    you make informed decisions and stay connected to the topics
                    you care about. <br />
                    <br />
                    Whether it's the latest in financial trends, practical tips
                    on saving, or the newest in sports, Cluberly News is your
                    reliable source for timely and relevant content.
                  </p>
                </ScrollAnimation>
              </div>
            </Col>
            <Col lg={5} className="d-flex justify-content-center">
              <div className="mob-phones">
                <ScrollAnimation animateIn="wobble" animateOnce={true}>
                  <img src={require("../Assets/images/news.webp")} />
                </ScrollAnimation>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
      <div className="circled-main black-line">
        <div className={`animated-box ${scrollY > 7000 ? "set-two" : ""}`}>
          <div className="circled-line">
            {/* <ScrollAnimation
              className="delay-one"
              animateIn="fadeInDown"
              animateOnce={true}
              durationAppear={10000}
            > */}
            <span className="first-circle"></span>
            {/* </ScrollAnimation> */}
            {/* <ScrollAnimation
              className="delay-two"
              animateIn="fadeInDown"
              animateOnce={true}
              durationAppear={10000}
            > */}
            <span className="long-line"></span>
            {/* </ScrollAnimation> */}
            {/* <ScrollAnimation
              className="delay-three"
              animateIn="fadeInDown"
              animateOnce={true}
              durationAppear={10000}
            > */}
            <span className="last-circle"></span>
            {/* </ScrollAnimation> */}
          </div>
        </div>
      </div>
      <section className="yellow-sec">
        <Container>
          <Row>
            <Col lg={12}>
              <ScrollAnimation animateIn="flipInY" animateOnce={true}>
                <h2 className="main-heading grey width-set">
                  <span>COUNTDOWN TO LAUNCH</span> <br />
                  UNVEILING A NEW ERA OF
                  <br /> SMART SAVING AND
                  <br /> COMMUNITY IMPACT THIS <br />
                  <span>SPRING!</span>
                </h2>
              </ScrollAnimation>
            </Col>
            <Col lg={10} className="mx-auto">
              <ScrollAnimation animateIn="zoomIn" animateOnce={true}>
                {" "}
                <p className="sub-font">
                  Get ready for the much-anticipated launch of Cluberly, your
                  gateway to revolutionising the way you save, invest, and
                  support community causes. As we finalise our innovative app,
                  we're inviting you to be a part of something groundbreaking.
                </p>{" "}
                <p className="sub-font mt-1">
                  Perfect for proactive savers, community-oriented clubs,
                  schools, and charities, Cluberly is more than just an app –
                  it's a commitment to financial empowerment and community
                  enrichment. Sign up now to join our waiting list or explore
                  partnership opportunities. Subscribe to our newsletter and be
                  the first to receive all the latest updates and exclusive
                  insights as we countdown to a spectacular spring launch!
                </p>
              </ScrollAnimation>
            </Col>
            <ScrollAnimation animateIn="fadeIn" animateOnce={true}>
              <Col lg={12} className="d-flex justify-content-center">
                <img
                  className="img-fluid"
                  src={require("../Assets/images/yellowphone.webp")}
                />
              </Col>
            </ScrollAnimation>
            <ScrollAnimation animateIn="bounceIn" animateOnce={true}>
              <Col lg={12}>
                <div className="register">
                  {" "}
                  <img src={require("../Assets/images/register.png")} />
                </div>
              </Col>
            </ScrollAnimation>
            <ScrollAnimation animateIn="fadeIn" animateOnce={true}>
              <Col lg={12}>
                <div className="sec-form">
                  <h2>Register your interest before we go live!</h2>
                  <Form>
                    <div className="d-flex align-items-center">
                      <Form.Group>
                        <Form.Label>First name</Form.Label>
                        <Form.Control type="text" placeholder="" />
                      </Form.Group>

                      <Form.Group>
                        <Form.Label>Last Name</Form.Label>
                        <Form.Control type="text" placeholder="" />
                      </Form.Group>
                      <Form.Group className="in-mail">
                        <Form.Label>Email</Form.Label>
                        <Form.Control type="email" placeholder="" />
                      </Form.Group>
                      <Form.Group className="d-flex flex-column">
                        <Form.Label style={{ opacity: "0" }}>vvdv</Form.Label>
                        <Button variant="primary" type="submit">
                          Register
                        </Button>
                      </Form.Group>
                    </div>
                    <Form.Group
                      className="mb-3 mt-0  "
                      controlId="formBasicCheckbox"
                    >
                      <div className="check-list">
                        <input
                          type="checkbox"
                          className="form-check-input"
                          id="flexCheckDefault"
                        />
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 7.40011 5.68606"
                          class="wixui-checkbox__icon"
                        >
                          <path d="M2.55016,5.68606c-.01709,0-.03369-.001-.05078-.002a.7.7,0,0,1-.51758-.28955L.1317,2.82326a.7.7,0,1,1,1.13672-.81738l1.36133,1.8916L6.19713.2134A.69995.69995,0,0,1,7.203,1.187L3.05309,5.47268A.7.7,0,0,1,2.55016,5.68606Z"></path>
                        </svg>
                        <label class="form-check-label" for="flexCheckDefault">
                          Add me to the list of first users
                        </label>
                      </div>
                    </Form.Group>
                  </Form>
                </div>
              </Col>
            </ScrollAnimation>
          </Row>
        </Container>
      </section>
      <ScrollAnimation animateIn="fadeIn" animateOnce={true}>
        <footer>
          <Container>
            <Row className="foot-inner">
              <Col>
                <div className="foot-menu first">
                  <h2>Socials</h2>
                  <div className="social-links">
                    <ul>
                      <li>
                        <Link to="">
                          <img src={require("../Assets/images/insta.webp")} />
                        </Link>
                      </li>
                      <li>
                        {" "}
                        <Link to="">
                          <img src={require("../Assets/images/fb.webp")} />
                        </Link>
                      </li>
                      <li>
                        <Link to="">
                          <img src={require("../Assets/images/twiter.webp")} />
                        </Link>
                      </li>
                      <li>
                        <Link to="">
                          <img src={require("../Assets/images/in.webp")} />
                        </Link>
                      </li>
                      <li>
                        <Link to="">
                          <img src={require("../Assets/images/youtube.webp")} />
                        </Link>
                      </li>
                      <li>
                        <Link to="">
                          <img src={require("../Assets/images/tiktok.webp")} />
                        </Link>
                      </li>
                    </ul>
                  </div>
                </div>
              </Col>
              <Col lg={4} md={6} sm={6}>
                <div className="foot-menu second">
                  <h2 className="">Company</h2>
                  <div className="foot-desc">
                    <p className="">
                      The Cluberly App Limited
                      <br /> trading as Cluberly
                      <br />
                      <br /> North Lodge, Hawkesyard,
                      <br /> Armitage Lane, Rugeley,
                      <br /> United Kingdom, WS15
                      <br /> 1PS <br />
                      <br /> Company Registration: <br />
                      13213572
                    </p>
                  </div>
                </div>
              </Col>
              <Col lg={3} md={6} sm={6}>
                <div className="foot-menu third">
                  <h2>Message Us</h2>
                  <div className="foot-form">
                    {" "}
                    <Form>
                      <Form.Group>
                        <Form.Label>First name</Form.Label>
                        <Form.Control type="text" placeholder="" />
                      </Form.Group>

                      <Form.Group>
                        <Form.Label>Last Name</Form.Label>
                        <Form.Control type="text" placeholder="" />
                      </Form.Group>
                      <Form.Group>
                        <Form.Label>Email</Form.Label>
                        <Form.Control type="email" placeholder="" />
                      </Form.Group>
                      <Form.Group>
                        <Form.Label>Message</Form.Label>
                        <Form.Control as="textarea" rows={3} />
                      </Form.Group>

                      <Button variant="primary" type="submit">
                        Submit
                      </Button>
                    </Form>
                  </div>
                </div>
              </Col>
              <Col lg={3} md={6} sm={6}>
                <div className="foot-menu">
                  <h2 className="before-tag">Get in Touch</h2>
                  <div className="touch-sec">
                    <div className="d-flex align-items-center mb-3">
                      <img src={require("../Assets/images/phone.webp")} />
                      <p>0800 123456</p>
                    </div>
                    <div className="d-flex align-items-center mb-3">
                      <img src={require("../Assets/images/envelop.webp")} />
                      <p>info@cluberlyapp.com</p>
                    </div>
                    <div className="d-flex align-items-start">
                      <img src={require("../Assets/images/location.webp")} />
                      <p>
                        The Cluberly App Ltd, North Lodge, Hawkesyard, Armitage
                        Lane, Rugeley, United Kingdom, WS15 1PS
                      </p>
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </footer>
      </ScrollAnimation>
    </>
  );
}
